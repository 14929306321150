import '../css/styles.css';
import '@splidejs/splide/css';

import * as bootstrap from 'bootstrap';
import { scroller } from './modules/smoothScroll.js';
import init from './init.js';
import 'lity/dist/lity.js';

((ready) => {
	if (document.readyState !== 'loading') {
		ready();
	} else {
		document.addEventListener('DOMContentLoaded', ready);
	}
})(() => {
	init(document);
});

if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR');
	});
}
