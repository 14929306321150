const modules = {
	'[data-navigation]': () => import('./modules/navigation'),
	'[data-tile-grid]': () => import('./modules/tileGrid'),
	'[data-hero]': () => import('./modules/heroReveal'),
	'[data-grid-hero]': () => import('./modules/gridHero'),
	'[data-node-reveal]': () => import('./modules/nodeReveal'),
	'[data-map-reveal]': () => import('./modules/mapReveal'),
	'[data-grid-reveal]': () => import('./modules/gridReveal'),
	'[data-hero-grid-reveal]': () => import('./modules/heroGridReveal'),
	'[data-callout-reveal]': () => import('./modules/calloutReveal'),
	'[data-image-reveal]': () => import('./modules/imageTileReveal'),
	'[data-quote-reveal]': () => import('./modules/quoteReveal'),
	'[data-newsletter-reveal]': () => import('./modules/newsletterReveal'),
	'[data-callout-block-reveal]': () => import('./modules/calloutBlockReveal'),
	'[data-slideshow]': () => import('./modules/slideshow'),
	'[data-carousel]': () => import('./modules/carousel'),
	'[data-mobile-navigation]': () => import('./modules/mobileNavigation'),
	'[data-video-embed]': () => import('./modules/videoEmbed.js'),
	'[data-splide-carousel]': () => import('./modules/splideCarousel.js'),
	'[data-ticker]': () => import('./modules/ticker.js'),
};

export default (scope) => {
	Object.keys(modules).forEach((selector) => {
		const request = modules[selector];

		((els) => {
			if (els && els.length) {
				request().then(({ default: module }) => module(els));
			}
		})(scope.querySelectorAll(selector));
	});
};
