import { gsap } from 'gsap';
import { ScrollTrigger, ScrollSmoother } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollSmoother);

ScrollTrigger.config({ ignoreMobileResize: true });

const scroller = ScrollSmoother.create({
	smooth: 2,
	effects: true,
	smoothTouch: true
});

export { scroller };
